<template>
<div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

        <!-- Login v1 -->
        <b-card class="mb-0">
            <b-card-title title-tag="h2" class="font-weight-bold mb-1">
                Welcome to Liden
            </b-card-title>
            <b-alert v-height-fade.appear :show="dismissCountDown" dismissible fade variant="danger" class="mb-0" @dismiss-count-down="countDownChanged">
                <div class="alert-body">
                    <span>{{errorMessage}}</span>
                </div>
            </b-alert>
            <validation-observer ref="loginValidation">

                <b-form class="auth-login-form mt-2">
                    <b-form-group label="Phone Number" label-for="phone">
                        <b-input-group>
                            <b-input-group-prepend>
                                <b-form-select v-model="countryCode" :options="countryCodeOptions" />
                            </b-input-group-prepend>
                            <cleave id="phone" v-model="phone" class="form-control" :raw="false" :options="options.phone" placeholder="7xxxxxxxx" />
                        </b-input-group>

                    </b-form-group>
                    <b-form-group>
                        <div class="d-flex justify-content-between">
                            <label for="login-password">Password</label>
                            <b-link :to="{name:'reset'}">
                                <small>Forgot Password?</small>
                            </b-link>
                        </div>
                        <validation-provider #default="{ errors }" name="Password" rules="required">
                            <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid':null">
                                <b-form-input id="login-password" v-model="password" :state="errors.length > 0 ? false:null" class="form-control-merge" :type="passwordFieldType" name="login-password" placeholder="············" />
                                <b-input-group-append is-text>
                                    <feather-icon class="cursor-pointer" :icon="passwordToggleIcon" @click="togglePasswordVisibility" />
                                </b-input-group-append>
                            </b-input-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>

                    <b-form-group>
                        <b-form-checkbox id="remember-me" v-model="status" name="checkbox-1">
                            Remember Me
                        </b-form-checkbox>
                    </b-form-group>

                    <b-button type="submit" variant="primary" block @click.prevent="validationForm" id="signin" :disabled="password ==''">
                        <b-spinner small v-show="isLoading" />
                        Sign in
                    </b-button>
                </b-form>
            </validation-observer>

            <b-card-text class="text-center mt-2">
                <span>New on our platform? </span>
                <b-link :to="{name:'signup'}">
                    <span>&nbsp;Create an account</span>
                </b-link>
            </b-card-text>
            <!--
            <div class="divider my-2">
                <div class="divider-text">
                    or
                </div>
            </div>
            <div class="auth-footer-btn d-flex justify-content-center">
                <b-button variant="google" href="javascript:void(0)">
                    <feather-icon icon="MailIcon" />
                </b-button>
                <b-button variant="github" href="javascript:void(0)">
                    <feather-icon icon="GithubIcon" />
                </b-button>
            </div>
        -->

        </b-card>
        <!-- /Login v1 -->
    </div>
</div>
</template>

<script>
/* eslint-disable global-require */
import {
    ValidationProvider,
    ValidationObserver
} from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
    BInputGroupPrepend,
    BSpinner,
    BAlert,
    BFormSelect,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCard,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
} from 'bootstrap-vue'
import {
    required,
    email
} from '@validations'
import {
    togglePasswordVisibility
} from '@core/mixins/ui/forms'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Cleave from 'vue-cleave-component'
import 'cleave.js/dist/addons/cleave-phone.us'
import {
    heightFade
} from '@core/directives/animations'
import Ripple from 'vue-ripple-directive'
import $ from 'jquery'
export default {
    components: {
        BRow,
        BCol,
        BLink,
        BFormSelect,
        BFormGroup,
        BFormInput,
        BInputGroupAppend,
        BInputGroup,
        BFormCheckbox,
        BCard,
        BCardText,
        BCardTitle,
        BImg,
        BForm,
        BButton,
        VuexyLogo,
        ValidationProvider,
        ValidationObserver,
        Cleave,
        BInputGroupPrepend,
        BSpinner,
        BAlert,
    },
    mixins: [togglePasswordVisibility],
    data() {
        return {
            isLoading: false,
            status: '',
            password: '',
            phone: '',
            countryCode: '254',
            sideImg: require('@/assets/images/pages/login-v2.svg'),
            // validation rulesimport store from '@/store/index'
            required,
            dismissSecs: 5,
            dismissCountDown: 0,
            showDismissibleAlert: false,
            errorMessage: "",
            email,
            countries: [],
            options: {
                phone: {
                    phone: true,
                    phoneRegionCode: 'KE',
                },
            },
            countryCodeOptions: [],
        }
    },
    directives: {
        heightFade,
        Ripple,
    },
    mounted() {
        let vm = this
        vm.getCountries()
    },
    computed: {
        passwordToggleIcon() {
            return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
        },
        imgUrl() {
            if (store.state.appConfig.layout.skin === 'dark') {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.sideImg = require('@/assets/images/pages/authImage.png')
                return this.sideImg
            }
            return this.sideImg
        },
    },
    methods: {
        countDownChanged(dismissCountDown) {
            this.dismissCountDown = dismissCountDown
        },
        validationForm() {
            let vm = this
            this.$refs.loginValidation.validate().then(success => {
                if (success) {
                    vm.isLoading = true
                    $('#signin').html(' Please Wait ...');
                    $.post({
                        url: store.state.rootUrl + 'account/v1/grant_access',
                        post: "POST",
                        // headers: {
                        //     "Content-Type": "application/json",
                        //     "Authorization": "Bearer " + store.state.accessToken
                        // },
                        data: JSON.stringify({
                            userName: vm.phone,
                            countryCode: vm.countryCode,
                            password: vm.password,
                            apigw: "WEB_GW"
                        }),
                        success: function (response, status, jQxhr) {
                            vm.isLoading = false
                            $('#signin').html('Sign Up');
                            if (response.data.code != 200) {
                                vm.dismissCountDown = vm.dismissSecs
                                vm.showDismissibleAlert = true
                                vm.errorMessage = response.data.message
                                if (response.data.code == 4001){
                                    vm.$cookies.set("username", vm.phone,"900s");
                                    vm.$cookies.set("country_code", vm.countryCode,"900s");
                                    vm.$toast({
                                        component: ToastificationContent,
                                        props: {
                                            title: response.data.message,
                                            icon: 'EditIcon',
                                            variant: 'warning',
                                        },
                                    })
                                    vm.$router.push({
                                        name: 'verification'
                                    });
                                }

                            } 
                            else {
                                vm.$router.push({
                                    name: 'home'
                                });
                                vm.$toast({
                                    component: ToastificationContent,
                                    props: {
                                        title: response.data.message,
                                        icon: 'EditIcon',
                                        variant: 'success',
                                    },
                                })
                                vm.$cookies.set("country_code", vm.countryCode)
                                vm.$cookies.set("token", response.data.data.token);
                                console.log("am here "+JSON.stringify(vm.parseJwt(response.data.data.client_data)))
                                vm.$cookies.set("clientData", vm.parseJwt(response.data.data.client_data));
                                
                            }

                        },
                        error: function (jQxhr, status, error) {
                            vm.isLoading = false
                            vm.dismissCountDown = vm.dismissSecs
                            $('#signin').html('Sign Up');
                            var errorMessage = jQxhr.responseJSON.data.message;
                            var statustext = jQxhr.responseJSON.statusDescription;
                            vm.showDismissibleAlert = true;
                            if (!statustext) {
                                vm.errorMessage = "An error occurred. Try again later.";
                            } else {
                                vm.errorMessage = statustext + " " + errorMessage;
                            }
                        }
                    });
                }
            });

        },
        getCountries() {
            let vm = this
            $.get({
                url: store.state.rootUrl + 'account/v1/view/countries',
                type: "get",
                async: true,
                crossDomain: true,
              
                success: function (response, status, jQxhr) {

                    $('#resetPWD').html(' Reset Password');
                    if (response.data.code != 200) {
                        vm.countries = []
                    } else {
                        let result = response.data.data;

                        for (const [key, value] of Object.entries(result)) {
                            console.log(`${key}: ${value[0]}`);
                            var country = {
                                value: `${key}`,
                                text: `${value[0]}` + " (+" + `${key}` + ")"

                            }
                            vm.countryCodeOptions.push(country)
                        }

                    }

                },
                error: function (jQxhr, status, error) {
                    vm.countries = []
                }
            });
        },
        parseJwt(token) {
            var base64Url = token.split('.')[1];
            var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));

            return JSON.parse(jsonPayload);
        }

    },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
